import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

import 'alpinejs'
import 'tailwindcss/tailwind.css'
import 'controllers'

import '@fontsource/oxanium'
import '@fontsource/inter'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()
