import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination])

export default class extends Controller {
  static targets = ['pagination', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1.2,
      centeredSlides: true,

      autoplay: {
        delay: 10000,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        type: 'bullets',
      },
      breakpoints: {
        648: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2,
        },
      },
    })
  }
}
