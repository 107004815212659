import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation])

export default class extends Controller {
  static targets = ['next', 'prev', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1.4,
      spaceBetween: 32,
      centeredSlides: true,
      autoplay: {
        delay: 10000,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },

      breakpoints: {
        640: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 2.5,
          centeredSlides: true,
        },

        1280: {
          slidesPerView: 3.4,
        },
      },
    })
  }
}
